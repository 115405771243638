import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, Container } from "react-bootstrap";
import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { SearchModal } from "../login/modals/SearchModal";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";
import {
  CA_GRADIENT_BUTTON,
  PINK_OCTOBER,
  TEXT_BLACK,
} from "../../helpers/colors";
import { academiaOptions } from "../../helpers/navbar";

const logoB = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Flogos%2Flogo1.png?alt=media&token=dffc6866-cf6d-4c14-a494-60ceaf3f3219";
const searchLogo = "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fecommerce%2FUilSearchAlt.png?alt=media&token=86c6da69-77b9-4c8a-ae2c-611fbd49e1f8";

const DesktopItem = styled(Col)`
  background-color: transparent;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 16px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  > a {
    text-decoration: none;
    color: #000 !important;
  }

  // TODO: Remove after 'Octubre Rosa'
  #october {
    color: ${PINK_OCTOBER} !important;
    font-style: italic;
    :hover {
      text-decoration: underline;
    }
  }
`;

const NavButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    color: #0cac8c;
  }
`;

const MobileLogo = styled.img`
  width: 50px;
  margin: auto;
  display: block;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
const DesktopLogo = styled.img`
  margin: auto;
  display: block;
  height: 40px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ShowMenu = styled.div`
  background-color: #ffffff;
  border: none;
`;

const StyledDropdown = styled.div`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 329px;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  h4 {
    color: ${TEXT_BLACK};
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  div {
    cursor: pointer;
  }
  div:hover {
    h4,
    p {
      background: ${CA_GRADIENT_BUTTON};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const Desktop = (props) => {
  const [showAcademiaDropdown, setShowAcademiaDropdown] = useState(false);

  const modal = document.getElementById("myModal");

  // For Google Analytics
  const gaEventTracker = useAnalyticsEventTracker("Navbar");

  useEffect(() => {
    const modalSearch = document.getElementById("modalSearch");
    const handleOpenModalSearch = () => {
      modal.style.display = "none";
      modalSearch.style.display = "block";
    };
    if (props.search?.length > 0) {
      handleOpenModalSearch();
    }
  }, [props.search]);

  useEffect(() => {
    document.addEventListener("click", function (event) {
      if (
        event.target.id !== "academia-dropdown" &&
        event.target.id !== "academia-button"
      ) {
        setShowAcademiaDropdown(false);
      }
    });

    return () => {
      // Clean up event listener when component unmounts
      document.removeEventListener("click", function (event) {
        if (event.target.id !== "academia-dropdown") {
          setShowAcademiaDropdown(false);
        }
      });
    };
  }, []);

  return (
    <div style={{ background: "#ffffff",boxShadow: "17px 11px 8px rgba(0, 0, 0, 0.05)", borderBottom: "1px solid #0CAC8C" }}>
      <Container
        style={{
          display: "flex",
          maxWidth: "1400px",
          width: "95%",
          position: "relative",
        }}
      >
        {/* Logo Labopat */}
        <DesktopItem
          md={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link to="/" replace>
            <MobileLogo
              alt="Logo Labopat"
              src={logoB}
              style={{ width: "100%", maxWidth: "164px", height: "auto" }}
            />{" "}
            <DesktopLogo
              alt="Logo Labopat"
              src={logoB}
              style={{ width: "100%", maxWidth: "164px", height: "auto" }}
            />{" "}
          </Link>
        </DesktopItem>

        {/* Navbar options */}
        <Col xs={9} className="row">
          <DesktopItem
            md={4}
            className="my-auto"
            style={{
              backgroundColor: "#ffffff",
              position: "relative",
              zIndex: "3",
            }}
          >
            <ShowMenu
              onClick={props.showTheMenu}
              style={{
                background: "none",
                padding: "0px",
                whiteSpace: "nowrap",
                width: "100%",
                zIndex: "2",
              }}
            >
              <NavButton
                id="botonMenu"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  margin: "0 auto",
                }}
              >
                Estudios y servicios
                {props.showMenu ? (
                  <UilAngleUp
                    size="32"
                    color="#00AD90"
                    style={{
                      marginLeft: "15px",
                      zIndex: "-2",
                    }}
                  />
                ) : (
                  <UilAngleDown
                    size="32"
                    color="#00AD90"
                    style={{
                      marginLeft: "15px",
                      zIndex: "-2",
                    }}
                  />
                )}
              </NavButton>
            </ShowMenu>
          </DesktopItem>
          <DesktopItem md={1} className="my-auto">

          </DesktopItem>
          {/*<DesktopItem md={2} className="my-auto">
            <a href="/estudiosrosas">
              <NavButton id='october'>Estudios Rosas</NavButton>
            </a>
                  </DesktopItem>*/}
          <DesktopItem md={3} className="my-auto">
            <a onClick={() => (window.location.href = "/nosotros")}>
              <NavButton>Nosotros</NavButton>
            </a>
          </DesktopItem>
          <DesktopItem md={3} className="my-auto">
            <a onClick={() => (window.location.href = "/medicos")}>
              <NavButton>Médicos</NavButton>
            </a>
          </DesktopItem>
          {/*<DesktopItem
            md={2}
            className="my-auto"
            style={{
              flexDirection: "column",
              display: "flex",
              margin: 0,
              height: "100%",
            }}
            id="academia-button"
            onMouseEnter={() => setShowAcademiaDropdown(true)}
            onMouseLeave={() => setShowAcademiaDropdown(false)}
          >
            <a id="academia-button">
              <NavButton
                id="academia-button"
                style={{ opacity: showAcademiaDropdown ? "0.3" : "1" }}
              >
                Academia
              </NavButton>
            </a>
            <div style={{ position: "relative", height: "0px", width: "100%" }}>
              {showAcademiaDropdown && (
                <StyledDropdown id="academia-dropdown">
                  {academiaOptions?.map((opt) => (
                    <div
                      onClick={() => (window.location.href = opt?.link)}
                      key={opt?.id}
                    >
                      <h4>{opt?.name}</h4>
                      <p>{opt?.description}</p>
                    </div>
                  ))}
                </StyledDropdown>
              )}
            </div>
          </DesktopItem>*/}
        </Col>

        {/* Search button */}
        <DesktopItem md={1} className="text-right">
          <Button
            variant="link"
            size="sm"
            onClick={() => {
              const searcher = document.getElementById("searcher-div");
              if (searcher !== null) {
                document.getElementById("searcher-div").style.display = "block";
              }
              gaEventTracker("abrir-buscador");
              props.showSearch();
            }}
          >
            <img
              src={searchLogo}
              width={"40px"}
              height={"40px"}
              alt="searchLogo"
            />
          </Button>
        </DesktopItem>

        {/* Search modal */}
        <SearchModal search={props.search} />
      </Container>
    </div>
  );
};
