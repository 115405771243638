import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { studyInCart } from "../../../../helpers/cart";
import handleAlert from "../../../../helpers/handleAlert";
import { handleAddItemToCart } from "../../../../actions/shoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

const FondoBanner =
  "https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Ffondo_banner_pediatrico.png?alt=media&token=c7971bb2-552d-429b-9553-12331fd30ba4";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 520px;
  /* overflow: hidden; */

  @media (min-width: 601px) and (max-width: 900px) {
    height: 520px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    height: 460px;
  }
`;

const BgBannerBox = styled.div`
  padding-top: 60px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Ffondo-blanco.png?alt=media&token=64428a18-728a-4bf5-b019-afb92b5ad21a");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  height: 100%;

  background-color: #533c68c2;
  @media (min-width: 901px) and (max-width: 1200px) {
    padding-top: 25px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    padding-top: 0px;
  }
  @media (max-width: 600px) {
    padding-top: 0px;
  }
`;

const Title = styled.h1`
  padding: 2px 0px 2px 0px;
  font-weight: 600;
  color: #fff;
  font-size: 36px;
  text-align: left;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #fff;
  text-decoration-skip-ink: auto;
  box-decoration-break: clone;
  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 29px;
    text-align: center;
    padding: 5px 0px 5px 0px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
    text-align: center;
    padding: 3px 0px 3px 0px;
    margin-bottom: 0;
  }
  @media (max-width: 600px) {
    font-size: 21px;
    text-align: left;
    padding: 4px 0px 4px 10px;
  }
`;

const PagoSucursal = styled.div`
  span {
    color: rgba(84, 86, 90, 1);
    font-size: 25px;
    font-weight: 600;

    @media (max-width: 600px) {
      font-size: 14px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 15px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 15px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 25px;
    color: rgba(84, 86, 90, 1);
    @media (max-width: 600px) {
      font-size: 15px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 20px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 23px;
    }
  }

  small {
  }
`;

const PagoLinea = styled.button`
  border: none;
  background: #fff;
  text-align: left;
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s;
  padding: 3px;

  &:active {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transform: translateY(0);
  }

  span {
    color: rgba(26, 166, 135, 1);
    font-size: 25px;
    border-bottom: 1px solid rgba(26, 166, 135, 1);
    font-weight: 600;

    @media (max-width: 600px) {
      font-size: 14px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 15px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 15px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 34px;
    color: rgba(26, 166, 135, 1);
    border-bottom: 1px solid rgba(26, 166, 135, 1);

    @media (max-width: 600px) {
      font-size: 15px;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 20px;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 23px;
    }
  }

  small {
  }
`;

const Paragraph = styled.p`
  font-weight: 700;
  padding: 2px 0px 2px 0px;
  font-size: 20px;
  color: #fff;
  line-height: 26px;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    padding: 4px 0px 4px 10px;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    padding: 5px 0px 5px 0px;
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 17px;
    line-height: 19px;
    text-align: center;
    padding: 5px 0px 5px 0px;
  }

  span {
    color: #e5dcf9;
  }
`;

const BoxPagos = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  text-align: left;
  position: relative;
  @media (max-width: 600px) {
    //padding: 10px 0px 10px 0px;
    gap: 40px;
    justify-content: space-evenly;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    justify-content: space-between;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    justify-content: space-between;
  }
`;
const Divider = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5px;
  opacity: 50%;
  border: 1px solid #000;
  height: 70%;
  display: none;
  background-color: #000000;
  @media (max-width: 600px) {
    display: block;
  }
`;

const BtnBox = styled.div`
  position: relative;
  padding: 10px 15px;

  display: flex;
  align-items: center;
  gap: 80px;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 2px;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    flex-direction: column;
    gap: 2px;
    position: relative;
    //padding: 5px 0px 5px 0px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    flex-direction: column;
    gap: 2px;
    position: relative;
    padding: 5px 0px 5px 0px;
  }

  button {
    background: linear-gradient(0deg, #1aa687, #1aa687);
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    padding: 3px 20px;
    border-radius: 15px;
    outline: none;
    border: none;

    @media (max-width: 600px) {
      font-size: 16px;
      padding: 2px 15px;
      position: absolute;
      left: 5%;
      margin-top: 5%;
      display: none;
    }

    @media (min-width: 601px) and (max-width: 900px) {
      font-size: 19px;
      padding: 5px 15px;
      position: absolute;
      left: 5%;
      display: none;
    }

    @media (min-width: 901px) and (max-width: 1200px) {
      font-size: 18px;
    }
  }
`;

const MarginBox = styled.div`
  background-color: #fff;
  padding: 40px 0px 70px 0px;
  @media (max-width: 600px) {
    padding: 0px 0px 5px 0px;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    padding: 0px 0px 5px 0px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    padding: 0px 0px 65px 0px;
  }
`;

const CustomImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    position: relative;
    /* top: -140px; */
  }

  @media (min-width: 901px) and (max-width: 1200px) {
  }
`;

const CustomParagrapBorder = styled.p`
  color: rgba(84, 86, 90, 1);
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 11px;
    //border-bottom: 3px solid ${(props) => props.color || "#000"};
  }
  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 13px;
    //border-bottom: 3px solid ${(props) => props.color || "#000"};
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 16px;

    //border-bottom: 5px solid ${(props) => props.color || "#000"};
  }
`;

const BgCustom = styled.div`
  background-color: #533c68c2;
  padding: 20px 15px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  position: relative;

  @media (max-width: 600px) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px 8px;
  }

  @media (min-width: 601px) and (max-width: 900px) {
    padding: 10px 8px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
  }
`;

const Descuento = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  right: 0;
  padding: 10px 30px;
  background-color: #f9ad62;
  text-align: center;
  font-size: 40px;
  line-height: 35px;
  font-weight: 600;
  color: #fff;

  span {
    display: inline-block;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    line-height: 20px;
    padding: 5px 15px;

    span {
      font-size: 20px;
    }
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 20px;
    line-height: 20px;
    padding: 5px 15px;

    span {
      font-size: 20px;
    }
  }

  @media (min-width: 901px) and (max-width: 1200px) {
    font-size: 20px;
    line-height: 20px;

    span {
      font-size: 20px;
    }
  }
`;

const BannerPediatrico = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 500);
    return () => clearTimeout(timeout);
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );
  const { items } = useSelector((state) => state.shoppingCart);

  const [study, setStudy] = useState({
    name: "",
    description: "",
    id: 0,
    normal_price: 0,
    promo_price: 0,
  });

  const [local, setLocal] = useState(false);
  const studyId =
    "panel-pediatrico-para-enfermedades-respiratorias-(14)-por-pcr";

  useEffect(() => {
    getInfo();
  }, []);
  const getInfo = async () => {
    try {
      let request = axios.get(
        `https://lpqsystem.com/obtener-estudio/${studyId}/${
          idClienteLabopat ? `${idClienteLabopat}` : "0"
        }`
      );
      let response = await request;
      setStudy({
        ...response.data,
        title: response.data.name,
        price: response.data.promo_price,
        oldPrice: response.data.normal_price,
      });
      if (response.data.categoria == 6) {
        setLocal(true);
      }
      if (response.data.categoria == 9) {
        setLocal(true);
      }
    } catch (error) {
      console.log(error);
      history.push("/not-found");
    }
  };

  const addStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study, false));
      //history.push("/checkout?sucursal=1");
    }
  };

  const payStudy = () => {
    const exists = studyInCart(study, items);
    if (exists?.exists) {
      handleAlert("warning", exists?.message, "Estudio/paquete ya agregado");
    } else {
      dispatch(handleAddItemToCart(study, false));
      history.push("/checkout");
    }
  };

  return (
    <Wrapper>
      {/* <Box
        sx={{
          height: "90%",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          width: { xs: "15px", md: "25px", lg: "30px" },
          background: "#7C6B8C",
          borderTopRightRadius: "12px",
          borderBottomRightRadius: "12px",
          zIndex: 999,
        }}
      /> */}
      <Grid
        container
        spacing={0}
        sx={{
          height: {
            xs: "auto",
            sm: "auto",
            md: "100%",
            lg: "100%",
            xl: "auto",
          },
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          lg={4}
          xl={4}
          sx={{
            position: "relative",
            width: "100%",
            height: {
              xs: "250px",
              sm: "260px",
              md: "100%",
              lg: "100%",
              xl: "auto",
            },
            zIndex: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <CustomImage
            src="https://firebasestorage.googleapis.com/v0/b/covid19-cc1fe.appspot.com/o/ecommerce%2Fassets%2Fbanner-home%2Fbanner-pediatrico.png?alt=media&token=827035e7-8e7b-4cb2-9588-e94b4db9f67e"
            alt="banner-pediatrico"
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          lg={8}
          xl={8}
          sx={{ position: "relative", width: "100%" }}
        >
          <BgBannerBox>
            <MarginBox>
              <BgCustom>
                <Descuento>
                  Hasta el <br /> <span> -40%</span>
                </Descuento>
                <NavLink to="/estudio/panel-pediatrico-para-enfermedades-respiratorias-(14)-por-pcr">
                  <Title>
                    Panel pediátrico para <br /> enfermedades respiratorias (14)
                    por PCR
                  </Title>
                </NavLink>
                <Paragraph>
                  Analizamos <span>14</span> tipos de virus y bacterias
                  <br /> causantes de enfermedades respiratorias en niños.
                </Paragraph>
              </BgCustom>
              <BoxPagos>
                <PagoLinea
                  className={animate ? "animate-vibrate" : ""}
                  onClick={() => payStudy()}
                >
                  <span>Pago en línea</span>
                  <h2>
                    $1,935.00 <small>MXN</small>
                  </h2>
                </PagoLinea>
                <Divider />

                <PagoSucursal>
                  <span>Pago en sucursal</span>
                  <h2>
                    $2,150.00 <small>MXN</small>
                  </h2>
                </PagoSucursal>
              </BoxPagos>

              <BtnBox>
                <CustomParagrapBorder>
                  *Promoción válida hasta el 31 de Enero 2025.
                </CustomParagrapBorder>
                <button onClick={() => addStudy()}>Agregar estudio</button>
              </BtnBox>
            </MarginBox>
          </BgBannerBox>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default BannerPediatrico;
