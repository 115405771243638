import React from 'react';
import { Helmet } from 'react-helmet-async';

const DynamicJSONLD = ({ schemaData }) => {
  return (
    <Helmet>
      <script type='application/ld+json'>{JSON.stringify(schemaData)}</script>
    </Helmet>
  )
}

export default DynamicJSONLD
