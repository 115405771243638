import {
  Container,
  FloatingLabel,
  Form,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import {
  UilSearchAlt,
  UilTimesCircle,
  UilClipboardAlt,
  UilPlusCircle,
  UilTrashAlt,
  UilTimes,
} from "@iconscout/react-unicons";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelmetTitle } from "../../helpers/HelmetTitle";
import axios from "axios";
import { Loader } from "../general/spinner";
import styled from "styled-components";
import { setPatientInfo } from "../../actions/patientInfo";
import {
  getTotalPrice,
  removeItemFromCart,
  setTotalPrice,
} from "../../actions/shoppingCart";
import { updateCartDB } from "../../helpers/api";
import { ListItemCart } from "../shopping-cart/listItemCart";
import { UIDsHome } from "../../helpers/cart";
import { PrivRoutes } from "../../routes/routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";

const StyledTable = styled(Table)`
  //width: 70%;
  th {
    border-color: ${(props) =>
      props.isdoctor === 1 ? "#4F90BA " : "#4F90BA"}!important;
  }
  tr {
    border-color: ${(props) =>
      props.isdoctor === 1
        ? "rgba(79, 144, 186, 0.3)"
        : "rgba(79, 144, 186, 0.3)"};
  }
`;

const SearchInput = styled(Form.Control)`
  background: 25% center rgb(225 240 254) !important;
  border: none;
  border-radius: 10px !important;
  height: 50px;
  padding-left: 40px;
  z-index: 1;
  ::-webkit-input-placeholder {
    font-size: 16px;
    color: #a6a6a6;
    margin-left: 40px;
  }
`;

const MyFloating = styled(FloatingLabel)`
  margin-bottom: 12px;
  width: 100%;
  .form-control {
    border: none;
    background: #4d99e5;
    border-radius: 12px;
    font-family: "IBM Plex Sans";
    max-height: 45px;
    padding: 0 0 0 40px;
    :not(:placeholder-shown) {
      padding-top: 0.6em;
    }
    :focus {
      padding-top: 0.6em;
    }
  }
  label {
    font-weight: normal;
    color: #828282;
    transform-origin: -10em -10em;
    border-radius: 8px;
    padding: 10px 0 10px 40px;
    height: auto;
    font-family: "IBM Plex Sans";
  }
  :active,
  :focus-within {
    label {
      background-color: transparent;
      margin-top: 5px;
      padding: 0 0 0 40px;
    }
  }
`;

const HeaderSection = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 57px;
  color: #474a4b;
  text-align: left;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

const NormalText = styled.p`
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 18px;
  line-height: 26.73px;
  color: #45494a;
  text-align: left;
  margin: 0;
`;

const NormalTextB = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #3b3b3b;
`;

const SearchButton = styled(Button)`
  font-family: "IBM Plex Sans";
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  line-height: 23.76px;
  text-align: center;
  margin: 0;
  max-width: 200px;
  width: 100%;
  background: #4d99e5;
  border: 2px solid #fff;
  max-height: 45px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  transition: all 0.4s ease;
  border-radius: 10px;
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: #fff;
  }
  @media only screen and (max-width: 576px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const EmptyPatients = styled.div`
  height: 50vh;
`;

const EmptyIconContainer = styled.div`
  margin: 40px auto 24px;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  left: 110px;
  top: 112px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyPatientsText = styled.p`
  font-family: "IBM Plex Sans";
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #45494a;
  margin-bottom: 40px;
`;

const TableHeaderTitle = styled.p`
  margin: 0;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  color: #45494a;
`;

const CreateOrder = styled(Button)`
  text-align: center;
  font-weight: 800;
  border: none;
  font-size: 15px;
  line-height: 22px;
  background: transparent;
  color: #4d99e5;
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: transparent;
  }
`;

const DeleteButton = styled(Button)`
  text-align: center;
  font-weight: 800;
  border: none;
  font-size: 15px;
  line-height: 22px;
  background: transparent;
  color: #c4c4c4;
  :hover {
    transition: all 0.4s ease;
    color: #4d99e5;
    background: transparent;
  }
`;

const StyledModal = styled(Modal)`
  .modal-header {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #4f90ba;
  }
  .btn-close {
    left: 93%;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-title {
    font-weight: 900;
    font-size: 22px;
    line-height: 33px;
    margin-right: auto;
    color: #4f90ba;
  }
  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    background: #4f90ba;
    border: none;
  }
  .foot-btn {
    max-width: 368px;
    width: 90%;
    margin: 0 auto;
    border: 2px solid #4f90ba;
    border-radius: 18px;
  }
  .row {
    width: 100%;
  }
  span {
    font-weight: 800;
  }
`;

const EmptyCart = styled.div`
  #empty {
    margin: 40px auto 24px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    left: 110px;
    top: 112px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    color: #474a4b;
    margin-bottom: 60px;
  }
`;

const CardCont = styled.div`
  max-width: 100%;
  max-height: auto;
  padding: 0rem;
  margin-left: 0px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border: 10 px;
  border: 2px solid transparent;
  border-radius: 12px;
  position: absolute;
`;

const WhiteButton1 = styled.button`
  width: 100%;
  //margin-left: 20%;
  height: 40px;
  background: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-top: -5px;
  transition: all 0.4s ease;
  :hover {
    color: #fff;
    transition: all 0.4s ease;
    background: ${(props) => props.mainColor};
  }
`;

const ListDiv = styled.div`
  overflow-y: auto;
  //padding-right: 7px;
  margin-right: 5px;
  max-height: 50vh;
  //max-width: 21vw;
  @media only screen and (max-width: 1600px) {
    max-height: 40vh;
  }
  @media only screen and (max-width: 1400px) {
    max-height: 50vh;
    //max-width: 27vw;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 117, 98, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const CardDiv = styled.div`
  padding: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(45deg, #fff, #fff),
    linear-gradient(
      90deg,
      #5097e3 -2.05%,
      #0cac8c 72.3%,
      #30df99 101.61%,
      #cef719 133.72%,
      #f5ec1d 211.91%
    );
  border-radius: 10px;
  border: 2px solid transparent;
  overflow-y: auto;
  margin: 5px;
  max-height: 40vh;
  @media only screen and (max-width: 1366px) {
    max-width: 22vw;
  }
`;

const StyledDrawer = styled.div`
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(
    90deg,
    #5097e3 -2.05%,
    #0cac8c 72.3%,
    #30df99 101.61%,
    #cef719 133.72%,
    #f5ec1d 211.91%
  );
  overflow: hidden;
  width: 100%;
  padding: 0.1rem;
  border-radius: 10px;
  border: 2px solid transparent;
  margin-top: -2px;
  margin-bottom: 20px;
  height: 90px;
  p {
    color: #fff;
    font-weight: 800;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 25px;
    line-height: 20px;
    margin-top: 0px;
    text-align: initial;
    margin-bottom: 12px;
    padding: 2rem;
  }
`;

const Bottom = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  border-radius:10px;
  @media only screen and (max-width: 1900px) {
    //bottom: 100px;
  }
  p {
    text-align: center;
    //margin-botton: 24px;
    .disabled {
      pointer-events: none;
      color: #c4c4c4;
    }
  }
  #genOrder {
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
    color: ${(props) => props.mainColor};
    text-decoration: underline;
    cursor: pointer;
  }
  #noItems {
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #a6a6a6;
    span {
      font-weight: 800;
    }
  }
`;

const Total = styled.h5`
  color: #6a6a6a;
  font-weight: 800;
  font-size: 15px;
  margin-top: 16px;
  position: relative;
  span {
    position: absolute;
    font-weight: 500;
    right: 0px;
    color: ${(props) => props.mainColor};
    text-align: right;
  }
  #black {
    color: #6a6a6a;
  }
`;

const BlueButton = styled.button`
  width: 80%;
  margin-left: 10%;
  height: 48px;
  background: ${(props) =>
    props.mainColor === "#4D99E5"
      ? "linear-gradient(84.3deg, #759ABD 15.24%, #97C6C0 97.95%)"
      : "linear-gradient(45deg, #5097E3 -97.58%, #0CAC8C -34.75%, #30DF99 77.36%, #CEF719 119.25%, #F5EC1D 138.96%)"};
  border-radius: 16px;
  border: 2px solid
    ${(props) => (props.mainColor === "#4D99E5" ? "#fff" : "#30DF99")};
  font-size: 16px;
  padding: 0;
  font-weight: 800;
  margin-bottom: 24px;
  color: #fff;
  transition: all 0.4s ease;
  :hover {
    transition: all 0.4s ease;
    background: #fff;
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

const ContinueButton = styled(Button)`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  width: 241px;
  height: 40px;
  background: ${(props) => props.mainColor};
  border: none;
  border-radius: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  :hover {
    border: 2px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    background: #fff;
  }
`;

export const Patients = ({ setShowCart, props }) => {
  const { items, totalPrice } = useSelector((state) => state.shoppingCart);
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const isDoctor =
    useSelector((state) => state.user?.data?.data?.profile?.type) === 1;
  const [totalSucursal, setTotalSucursal] = useState();
  const gaEventTracker = useAnalyticsEventTracker("Cart");
  var isOrder = urlParams.get("doctor");

  const idClienteLabopat = useSelector(
    (state) => state.user?.data?.data?.profile?.idClienteLabopat
  );

  useEffect(() => {
    getTotal();
  }, [items]);

  const [showCallModal, setShowCallModal] = useState(false);

  const getTotal = () => {
    const pricesArray = items?.map((item) => item.oldPrice);
    const final = pricesArray.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );
    setTotalSucursal(final);
  };

  // Check if is doctor
  useEffect(() => {
    if (!isDoctor) {
      window.location.href = "/";
    }
  }, []);

  // Orders
  const uid = useSelector((state) => state?.auth?.uid);
  const [patients, setPatients] = useState([]);
  const mainColor =
    PrivRoutes.includes(location.pathname) && isDoctor ? "#4F90BA" : "#00AC90";
  const [patient, setPatient] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const editing = false; // Change to state?
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [modalPatient, setModalPatient] = useState();
  const isMobile = width < 630;
  const isDesktop = width > 1366;

  useEffect(() => {
    // Search patients
  }, [search]);

  useEffect(() => {
    dd();
  }, []);

  const dd = async () => {
    try {
      //setLoading(true);
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/mis-pacientes?user=${uid}`
      );
      setPatients(requestPending.data);
      setFilteredPatients(requestPending.data);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.log(error);
    }
  };

  const ded = async (patient) => {
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/detalle-solicitud-paciente?id=${patient}`
      );
      setPatient(requestPending.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveItemFromCart = (id) => {
    let currentItem = items.find((item) => item.id === id);
    const newCart = items.filter((item) => item.id !== id);
    let data = newCart;
    console.log(currentItem);
    if (currentItem.howMany > 1) {
      console.log("IF");
      // Original price
      const originalPrice = currentItem.price / currentItem.howMany;
      const newHowMany = currentItem.howMany - 1;
      currentItem.price = originalPrice * newHowMany;
      currentItem.howMany = newHowMany;
      data = [...newCart, currentItem];
      console.log("DATA: ", data);
    } else {
      console.log("else 3");
    }
    // console.log("get total 4: ", data)
    const price = getTotalPrice(data);

    // Update cart
    dispatch(removeItemFromCart(data));

    // Update total price
    dispatch(setTotalPrice(price));

    // Update cart in DB
    dispatch(updateCartDB(data, price));
  };

  const vaciarCarrito = () => {
    // Eliminar todos los elementos del carrito
    dispatch(removeItemFromCart([])); // Pasar un array vacío elimina todos los elementos
    // Actualizar el precio total a cero
    dispatch(setTotalPrice(0));
    // Actualizar el carrito en la base de datos
    dispatch(updateCartDB([], 0)); // Pasar un array vacío y cero como precio
  };

  const someNotAvailableOnline = () => {
    return items.some(
      (study) =>
        study.categoria === 10 ||
        study.categoria === 8 ||
        study.ventaOnline === 0
    );
  };

  const callToSchedule = () => {
    window.location.href =
      `https://wa.me/522212311313?text=Quiero agendar una cita para:` +
      items.map((study) => " " + study.name);
  };

  const deletePatient = async () => {
    setLoading(true);
    try {
      // TODO: Change endpoint to patients
      let requestPending = await axios.get(
        `https://lpqsystem.com/mis-pacientes?user=${uid}&id=${patient?.solicitud[0]?.solicitud?.id}`
      );
      console.log(requestPending.data);
      setLoading(false);
      setPatient([]);
      setPatients(requestPending.data);
      setFilteredPatients(requestPending.data);
      setShowDeleteModal(false);
      history.push("/pacientes");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const filterPatients = () => {
    if (search !== "") {
      setFilteredPatients(
        patients
          .map((patient) => {
            if (
              `${patient?.nombre?.toLowerCase() ?? ""} ${
                patient.app?.toLowerCase() ?? ""
              } ${patient.apm?.toLowerCase() ?? ""}`.includes(
                search?.toLowerCase()
              ) ||
              patient?.emailContacto
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) ||
              patient?.fnacimiento?.includes(search?.toLowerCase()) ||
              patient?.telContacto?.includes(search?.toLowerCase())
            ) {
              return patient;
            } else {
              return undefined;
            }
          })
          .filter((e) => e !== undefined)
      );
    }
  };

  const resetFilters = () => {
    setSearch("");
    setFilteredPatients(patients);
  };

  const handleClick = (patient) => {
    setModalPatient(patient);
    setShowOrderModal(true);
  };

  const handleClose = () => {
    setModalPatient(undefined);
    setShowOrderModal(false);
  };

  const createOrder = (way) => {
    // way = 0 is for cart
    // way = 1 is for packages
    const tempPatientInfo = {
      id: modalPatient?.id,
      name: modalPatient?.nombre ?? "",
      fathersLastname: modalPatient.app ?? "",
      mothersLastname: modalPatient.apm ?? "",
      email: modalPatient?.emailContacto ?? "",
      phone:
        modalPatient?.telContacto && modalPatient?.telContacto !== "undefined"
          ? modalPatient?.telContacto
          : "+52",
      fnacimiento: modalPatient?.fnacimiento ?? "",
      gender: modalPatient?.genero ?? "",
    };
    localStorage.setItem("patientInfo", JSON.stringify(tempPatientInfo));
    dispatch(setPatientInfo(tempPatientInfo));
    if (way === 1) {
      history.push("mis-paquetes?forPatient=1");
    } else {
      setShowCart();
      setShowOrderModal(false);
    }
  };

  const handleOrder = () => {
    const patientInfo = JSON.parse(localStorage?.getItem("patientInfo"));

    gaEventTracker("carrito-generar-order");
    if (window.location.pathname === "/checkout" && !isOrder) {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
      window.location.reload();
    } else {
      history.push(
        `/checkout?doctor=1${patientInfo?.id >= 1 ? "&forPatient=1" : ""}`
      );
    }
  };

  const currency = function (number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(number);
  };

  return (
    <Container
      style={{ minHeight: isMobile ? "180vh" : "150vh" !== isDesktop ? "140vh" : ""}}
      className={width < 768 ? "mt-5 pt-4" : ""}
    >
      <HelmetTitle title="Pacientes" name="description" content="content" />
      <Row className="mb-5">
        <HeaderSection>
          <HeaderTitle>Expediente de pacientes</HeaderTitle>
        </HeaderSection>
      </Row>
      <Row className="mb-4">
        <NormalText>
          Revisa el expediente médico y consulta los resultados de sus estudios
        </NormalText>
      </Row>
     
        <Row>
          <Col xs={12} md={6} lg={4}>
            <div style={{ position: "relative" }}>
              <UilSearchAlt
                style={{
                  zIndex: 50,
                  color: "#A6A6A6",
                  position: "absolute",
                  left: "10px",
                  top: "10px",
                }}
              />
              {search !== "" && (
                <UilTimesCircle
                  style={{
                    zIndex: 50,
                    color: "#A6A6A6",
                    position: "absolute",
                    right: "10px",
                    top: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSearch("")}
                />
              )}
              <MyFloating label="Buscar en el listado de pacientes">
                <SearchInput
                  id="search-input"
                  autocomplete="off"
                  mainColor={"#4F90BA"}
                  type="text"
                  placeholder="Buscar en el listado de pacientes"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  autofocus
                />
              </MyFloating>
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex align-items-center justify-content-center"
          >
            <SearchButton
              style={{ marginTop: "-10px" }}
              onClick={() => filterPatients()}
            >
              Continuar
            </SearchButton>
          </Col>
          {patients?.length !== filteredPatients?.length && (
            <Col
              xs={12}
              md={6}
              lg={3}
              className="d-flex align-items-center justify-content-center"
            >
              <SearchButton
                style={{
                  color: "#4F90BA !important",
                  background: "#FFFFFF !important",
                }}
                onClick={() => resetFilters()}
              >
                Ver todos
              </SearchButton>
            </Col>
          )}
        </Row>
        <Row className="justify-content-center mb-5">
          <Col md={12}>
            {patients.length > 0 ? (
              <StyledTable responsive hover striped>
                <thead>
                  <tr>
                    <th>
                      <TableHeaderTitle>Paciente</TableHeaderTitle>
                    </th>
                    <th>
                      <TableHeaderTitle>Correo</TableHeaderTitle>
                    </th>
                    <th>
                      <TableHeaderTitle>Teléfono</TableHeaderTitle>
                    </th>
                    <th>
                      <TableHeaderTitle>F.nacimiento</TableHeaderTitle>
                    </th>
                    <th>
                      <TableHeaderTitle>Fecha de creación</TableHeaderTitle>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPatients.length > 0 &&
                    filteredPatients?.map((patient, index) => (
                      <tr>
                        <td
                          className="pt-4 pb-4"
                          onClick={() =>
                            history.push(`/paciente/${patient.id}`)
                          }
                        >
                          <NormalTextB style={{ textDecoration: "underline" }}>
                            {patient.nombre} {patient.app} {patient.apm}
                          </NormalTextB>
                        </td>
                        <td
                          className="pt-4 pb-4"
                          onClick={() =>
                            history.push(`/paciente/${patient.id}`)
                          }
                        >
                          <NormalTextB>{patient.emailContacto}</NormalTextB>
                        </td>
                        <td
                          className="pt-4 pb-4"
                          onClick={() =>
                            history.push(`/paciente/${patient.id}`)
                          }
                        >
                          <NormalTextB>{patient.telContacto}</NormalTextB>
                        </td>
                        <td
                          className="pt-4 pb-4"
                          onClick={() =>
                            history.push(`/paciente/${patient.id}`)
                          }
                          f
                        >
                          <NormalTextB>{patient.fnacimiento}</NormalTextB>
                        </td>
                        <td
                          className="pt-4 pb-4"
                          onClick={() =>
                            history.push(`/paciente/${patient.id}`)
                          }
                          f
                        >
                          <NormalTextB>{patient.created_at}</NormalTextB>
                        </td>
                        <td>
                          <CreateOrder onClick={() => handleClick(patient)}>
                            <UilPlusCircle />
                            <br />
                            Crear orden
                          </CreateOrder>
                          {!editing && (
                            <DeleteButton
                              onClick={() => {
                                setShowDeleteModal(true);
                                ded(`${patient.id}`);
                              }}
                            >
                              <UilTrashAlt />
                              <br /> &nbsp;&nbsp;Eliminar
                            </DeleteButton>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </StyledTable>
            ) : (
              <EmptyPatients>
                <EmptyIconContainer>
                  <UilClipboardAlt color="#A6A6A6" size={100} />
                </EmptyIconContainer>
                <EmptyPatientsText>Aún no tienes pacientes</EmptyPatientsText>
              </EmptyPatients>
            )}
          </Col>
        </Row>

        {loading ? <Loader show={loading} /> : null}
        <StyledModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Eliminar Paciente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              ¿Estás seguro de eliminar al paciente: "
              {patient?.solicitud
                ? `${patient?.solicitud[0]?.solicitud?.nombre} ${patient?.solicitud[0]?.solicitud?.app} ${patient?.solicitud[0]?.solicitud?.apm}`
                : ""}
              " del Expediente de pacientes?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Button
                className="foot-btn"
                variant="primary"
                onClick={() => deletePatient()}
              >
                Eliminar
              </Button>
            </Row>
            <Row>
              <Button
                style={{
                  color: "#4F90BA",
                  background: "none",
                  marginTop: "15px",
                }}
                className="foot-btn"
                variant="light"
                onClick={() => setShowDeleteModal(false)}
              >
                Regresar
              </Button>
            </Row>
          </Modal.Footer>
        </StyledModal>
        <StyledModal show={showOrderModal} onHide={() => handleClose()}>
          <div onClick={() => handleClose()} style={{ marginBottom: "-20px" }}>
            <UilTimes color="#fff" style={{ cursor: "pointer" }} />
          </div>
          <Modal.Header closeButton>
            <Modal.Title>Crear orden</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>¿Con que estudios deseas crear la orden?</p>
            <Row>
              <Col xs={12} md={6}>
                <SearchButton onClick={() => createOrder(0)}>
                  Carrito
                </SearchButton>
              </Col>
              <Col xs={12} md={6}>
                <SearchButton onClick={() => createOrder(1)}>
                  Paquetes
                </SearchButton>
              </Col>
            </Row>
          </Modal.Body>
        </StyledModal>
    
    </Container>
  );
};